<template>
  <!-- 颜色选择 -->
  <el-dialog
    title="颜色选择"
    :visible.sync="showTypeColor"
    width="732px"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="showTypeColor = false"
    class="custem-dialog"
    v-dialogDrag
    v-disable-contextmenu
  >
    <div class="cad-select_wrapper" v-stopdrag>
      <div class="block-wrapper">
        <div class="color-wrapper">
          <div class="color-item-box">
            <div
              class="color-item"
              v-for="item in colorLists"
              :key="item.index"
              :style="{ background: Rgb2Hex(item.color) }"
              @click="selectColor(item, item.index)"
              :class="{ 'color-item-active': colorIndex == item.index }"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="String(item.index)"
                placement="top"
              >
                <div>
                  <div
                    class="active-box"
                    v-show="colorIndex == item.index"
                  ></div>
                  <!-- <i
                      v-show="colorIndex == item.index"
                      class="el-icon-check color-active"
                    ></i> -->
                </div>
              </el-tooltip>
            </div>
          </div>
          <div class="flex color-btn-box">
            常用颜色：
            <div
              class="color-common-item"
              v-for="item in useColors"
              :key="item.index"
              :style="{ background: Rgb2Hex(item.color) }"
              :class="{ 'color-item-active': colorIndex == item.index }"
              @click="selectColor(item, item.index)"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="String(item.index)"
                placement="top"
              >
                <div>
                  <div
                    class="active-box"
                    v-show="colorIndex == item.index"
                  ></div>
                </div>
              </el-tooltip>
            </div>
          </div>
          <div class="flex color-btn-box" style="margin-top:12px;">
            <div
              class="color-common-item"
              v-for="item in useColorss"
              :key="item.index"
              :style="{ background: Rgb2Hex(item.color) }"
              :class="{ 'color-item-active': colorIndex == item.index }"
              @click="selectColor(item, item.index)"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="String(item.index)"
                placement="top"
              >
                <div>
                  <div
                    class="active-box"
                    v-show="colorIndex == item.index"
                  ></div>
                </div>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="color-select-box">
        <div class="search-color">
          <div class="color-label">颜色(下标)：</div>
          <el-input
            placeholder="请输入颜色(下标)"
            v-model="colorIndex"
            v-stopdrag
            @blur="searchColor"
            @change="searchColor"
            @keyup.enter.native.stop="searchColor"
          >
          </el-input>
        </div>
        <div
          v-if="colorVal"
          class="show-color-box"
          :style="{ background: colorVal }"
        >
          <div
            class="show-color-border"
            :style="{ background: colorVal }"
          ></div>
          <div class="show-color" :style="{ background: colorVal }"></div>
        </div>
      </div>
    </div>
    <div slot="footer" class="flex-center block-footer">
      <el-button
        @click="showTypeColor = false"
        size="nomal"
        native-type="button"
        >取消</el-button
      >
      <el-button @click="sureColorVal" type="primary" size="nomal" v-stopdrag
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {},
  mixins: [],
  computed: {
    colorLists() {
      return window.colorLists;
    },
    colorList() {
      return window.colors;
    },
    useColors() {
      return window.colorLists.slice(1, 10);
    },
    useColorss() {
      return window.colorLists.slice(
        window.colorLists.length - 5,
        window.colorLists.length
      );
    }
  },
  data() {
    return {
      dialogType: "",
      colorValue: "",
      colorVal: "", //颜色值
      colorIndex: "", //颜色下标
      showTypeColor: false
    };
  },
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    // 选择其他颜色
    showDialog(value, pixiMap, type = "") {
      this.dialogType = type;
      let dataColor = this.judgeColor(value);
      console.log("showDialog", value, dataColor);
      this.colorIndex = dataColor.index;
      this.colorVal = dataColor.color;
      this.showTypeColor = true;
    },
    // 选择颜色
    selectColor(item, index = "") {
      this.colorVal = item && item.color ? this.Rgb2Hex(item.color) : "";
      this.colorIndex = index == "" ? "" : Number(index);
    },
    // 颜色确定
    sureColorVal() {
      if (!this.colorVal) this.$message.warning("不是有效的颜色名或值.");
      this.selectColorData();
    },
    selectColorData() {
      let dataColor = {
        index: this.colorIndex,
        val: this.colorVal.replace(/#/gi, "0X"),
        value: this.colorVal
      };
      this.showTypeColor = false;
      if (this.dialogType == "background") {
        window.cadmap.backgroundColor = dataColor.val;
      } else this.$emit("callBackColor", dataColor);
    },
    // 颜色搜索
    searchColor() {
      let num = this.colorIndex.replace(/[^\-?\d]/g, "");
      if (num == "") {
        this.colorVal = "";
        this.$message.warning("不是有效的颜色名或值.");
      } else {
        num = Number(this.colorIndex.replace(/[^\-?\d]/g, ""));
        if (num >= 0 && num <= this.colorLists.length - 1) {
          this.colorVal = this.Rgb2Hex(this.colorLists[num].color);
        } else {
          this.colorVal = "";
          this.$message.warning("不是有效的颜色名或值.");
        }
      }
    },
    judgeColor(val) {
      let valData = {
        color: "#FFF",
        index: -1
      };
      let valColor = JSON.parse(JSON.stringify(val));
      if (val) {
        if (valColor.indexOf("#") > -1 || valColor.indexOf("0x") > -1) {
          if (valColor.indexOf("0x")) {
            valData.color = this.Rgb2Hex(valColor);
          } else valData.color = valColor;
          valData.color = valColor.replace(/ffffff/gi, "FFFFFF");
          this.colorLists.some((item, idx) => {
            if (
              this.$judgeColor(valData.color.replace(/#/gi, "0x"), item.color)
            ) {
              valData.index = idx;
              return true;
            }
          });
        }
      } else if (val == 0) {
        valData.index = 0;
        valData.color = this.colorList[0].color;
      } else {
        valData.color = this.colorList[val].color;
      }

      return valData;
    },
    Rgb2Hex(color) {
      let hex = color.replace(/0x/gi, "#");
      return hex;
    }
  },
  watch: {}
};
</script>
<style scoped lang="stylus">
.color-input-box
  position relative
  width 100%
  height 100%

  .icon-select
    position absolute
    top 50%
    left 15px
    z-index 110
    transform translateY(-50%)

  >>>.el-select
    width 100%
    height 100%
    line-height auto

  >>>.el-input__inner
    padding-left 32px !important

.color-list-item
  position relative
  padding 0 10px
  height 34px
  display flex
  align-items center
  cursor pointerf

.attar-icon
  background #ffffff
  border 1px solid #000
  width 14px
  height 14px
  border-radius 2px
  margin-right 10px

// 颜色框样式
.color-item-box
  display flex
  flex-wrap wrap
  padding-right 8px

  .color-item
    position relative
    width 16px
    height 16px
    margin-left 3px
    margin-bottom 3px

    div
      position relative
      width 100%
      height 100%
      border 1px solid #000
      border-radius 2px
      box-sizing border-box
      cursor pointer

.color-btn-box
  margin-top 32px
  flex-wrap wrap
  padding-right 8px

  .color-common-item
    position relative
    width 16px
    height 16px
    margin-left 3px
    margin-bottom 3px

    div
      position relative
      width 100%
      height 100%
      border 1px solid #000
      border-radius 2px
      box-sizing border-box
      cursor pointer

.color-active
  position absolute
  top 50%
  left 50%
  z-index 100
  color red
  font-weight 700
  font-size 36px
  transform translate(-50%, -50%)

.color-select-box
  margin 32px 32px 0 0
  display flex
  align-items center
  justify-content space-between

  .color-label
    margin-bottom 5px

  >>>.el-input
    flex 0 0 80% !important
    width 80% !important

  >>>.el-input__inner
    border none
    border-radius 0
    border-bottom 1px solid #000

  .show-color-box
    position relative
    width 50px
    height 50px
    padding 8px
    box-sizing border-box
    border 1px solid #b5b7bc

    .show-color-border
      position absolute
      width 21px
      height 21px
      top 0px
      right 0px
      z-index 3

    .show-color
      position absolute
      width 40px
      height 40px
      top -20px
      right -20px
      padding 8px
      box-sizing border-box
      border 1px solid #b5b7bc
      z-index 1

.color-item-active
  position relative

  .active-box
    position absolute
    top -3px
    left -3px
    width 20px !important
    height 20px !important
    border 1px solid red !important

  div
    border-color transparent !important
</style>
