import { render, staticRenderFns } from "./dialogColor.vue?vue&type=template&id=04c7738d&scoped=true"
import script from "./dialogColor.vue?vue&type=script&lang=js"
export * from "./dialogColor.vue?vue&type=script&lang=js"
import style0 from "./dialogColor.vue?vue&type=style&index=0&id=04c7738d&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04c7738d",
  null
  
)

export default component.exports